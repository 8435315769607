/* SlideImg.css */
.swiper-button-next,
.swiper-button-prev {
  color: #494949; /* Change the color of navigation arrows */
}

.swiper-pagination-bullet {
  background: #494949; /* Change the color of pagination bullets */
}

.swiper-pagination-bullet-active {
  background: #494949; /* Change the color of active pagination bullet */
}
